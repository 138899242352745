import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="404: Not Found" />
        <div className="error-404-page">
          <div className="container">
            <div className="row">
              <div className="col-2-3 col-small-1-2 vertical-align-middle">
                <h1>Error 404</h1>
                <h2>The page you are looking for cannot be found.</h2>
                <Link to="/" className="button">Go home</Link>
              </div>
              <div className="col-1-3 col-small-1-2 vertical-align-middle">
                <img className="door-404 pull-right" src="/images/door-404.svg" />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
